import { GetServerSideProps } from "next";
import dynamic from "next/dynamic";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { GetStoreItemsParams } from "../../api/interfaces/items.interface";
import { GetStoreItems } from "../../api/items";
import { GetStoreBankAccount } from "../../api/stores";
import { useFetcher, useRequest } from "../../api/utils";
import { ProductItemInterface, StoreInterface, WABotSession } from "../../assets/interfaces";
import { STORE_FRONT_VIEW_MODES } from "../../assets/js/utils/constants";
import {
  checkItemAvailability,
  checkStoreIsOpen,
  computeItemAvailability,
  getStoreFrontSEOTags,
} from "../../assets/js/utils/utils";
import useAnalytics, { PAGE_TYPE } from "../../components/hooks/analytics";
import { useModals } from "../../components/hooks/useModals";
import usePagination from "../../components/hooks/usePagination";
import useSearchParams from "../../components/hooks/useSearchParams";
import Portal from "../../components/portal";
import SEOTags from "../../components/seo-tags";
import {
  StoreFrontDetails,
  StoreFrontHeader,
  StoreFrontTabs,
  ListView,
  StoreFrontCart,
  Error,
  StoreFrontErrors,
  ExplainerBanner,
  StoreFrontExplainerModal,
} from "../../components/store-front";
import { ItemsFetchError } from "../../components/store-front/errors";
import ItemsGridView from "../../components/store-front/items-grid";
import ProductDetailsModal from "../../components/store-front/modals/product-details";
import PublicFooter from "../../components/ui/public-footer";
import CartContext from "../../contexts/cart-context";
import StoreMaintenanceMode from "../../components/store-front/store-maintenance-mode";
import SlugDisabledMode from "@/components/store-front/slug-disabled-mode";
import { useAppendScript } from "@/components/hooks/useAppendScript";
import { hexToRgb, generateComplementaryHexColor3, isValidHexCode } from "@/assets/js/utils/functions";
import fetchStoreData from "@/assets/js/utils/fetch-store-data";
import { useFacebookPixel } from "@/components/hooks/useFacebookPixel";

const SwipingCardsView = dynamic(() => import("../../components/store-front/swiping-cards"), { ssr: false });

export interface StoreFrontProps {
  error: boolean;
  data?: {
    store?: StoreInterface;
    category?: string;
    search?: string;
  };
  notFound: boolean;
  disabled_slug?: boolean;
  slug?: string;
  query?: { [key: string]: string };
  isMini?: boolean;
  botSession?: WABotSession;
}

const StoreFront: React.FC<StoreFrontProps> = (props) => {
  return <StoreFrontMain {...props} />;
};

export const StoreFrontMain: React.FC<StoreFrontProps> = (props) => {
  const { error, data, notFound, disabled_slug, slug, query, botSession } = props;
  const PER_PAGE = 10;
  const { currentPage, goNext: handleNext, setPage } = usePagination();
  const store = data?.store;
  const [viewMode, setViewMode] = useState(store?.configuration.view_modes.default);
  const { category, search } = useSearchParams(["category", "search"]);
  const { modals, toggleModal } = useModals(["explainer", "product_details"]);
  const tabsRef = useRef<HTMLDivElement>(null);
  const [isTabsShowing, setIsTabsShowing] = useState(true);
  const [fetchingCategory, setFetchingCategory] = useState(false);
  const [items, setItems] = useState<ProductItemInterface[]>([]);
  const [featuredItems, setFeaturedItems] = useState<ProductItemInterface[]>([]);
  const [pagData, setPagData] = useState({ next_page: null, prev_page: null, total_pages: null });
  const { saveAnalytics } = useAnalytics(PAGE_TYPE.STORE, query?.ref);
  const storeIs = checkStoreIsOpen(store);
  const [selectedItem, setSelectedItem] = useState<ProductItemInterface>(null);
  const [fullySetup, setFullySetup] = useState(false);
  const fetchStoreBankAccountReq = useFetcher(GetStoreBankAccount, { id: store?.id });
  useFacebookPixel(store?.configuration?.facebook_pixel_enabled ? store?.configuration?.fb_pixel : null);
  const maintenanceMode = store?.maintenance_mode ?? false;
  const isMini = props?.isMini === true;

  const getStoreIsClosedMsgToDisplay = () => {
    const checkBack = "We're closed at the moment. Check back";
    const at = storeIs.openTomorrowTime.length ? "at" : "";
    if (storeIs.visitedBeforeOpening) return `${checkBack} at ${storeIs.openTime}.`;
    if (storeIs.visitedAfterClosing) return `${checkBack} ${at} ${storeIs.openTomorrowTime} tomorrow.`;
  };

  const formattedItems = useMemo(() => {
    return items.map((i) => computeItemAvailability(i));
  }, [items]);

  const formattedFeaturedItems = useMemo(() => {
    return featuredItems.map((i) => computeItemAvailability(i));
  }, [featuredItems]);

  const getFilter = useMemo(() => {
    if (error || notFound) {
      return { store: null };
    }

    const filter = {
      store: store?.id,
    };

    if (category || data?.category) {
      filter["category"] = category || data.category;
    }

    if (search || data?.search) {
      filter["search"] = search || data.search;
    }

    return filter;
  }, [category, search]);

  const {
    response,
    error: itemsFetchError,
    isLoading,
    makeRequest,
  } = useFetcher<GetStoreItemsParams>(
    GetStoreItems,
    {
      filter: getFilter,
      page: currentPage,
      per_page: PER_PAGE,
    },
    [store?.id]
  );

  const computedLoadingState = isLoading || (response?.data && formattedItems.length < 1);

  useEffect(() => {
    if (store) {
      window.COUNTRY = store.country;
      // setupCurrencySettings(store);

      if (store?.configuration?.color && isValidHexCode(store?.configuration?.color)) {
        const p = hexToRgb(store?.configuration?.color);
        const primaryRGB = `${p.r}, ${p.g}, ${p.b}`;
        document.documentElement.style.setProperty("--primary-500", primaryRGB);
        document.documentElement.style.setProperty("--discount-badge", primaryRGB);

        const s = hexToRgb(generateComplementaryHexColor3(store?.configuration?.color));
        const secondaryRGB = `${s.r}, ${s.g}, ${s.b}`;
        document.documentElement.style.setProperty("--accent-yellow-500", secondaryRGB);
        document.documentElement.style.setProperty("--add-to-cart", secondaryRGB);
        document.documentElement.style.setProperty("--success", secondaryRGB);
        document.documentElement.style.setProperty("--cart-badge", secondaryRGB);
      }

      setTimeout(() => {
        setFullySetup(true);
        saveAnalytics({ store: store?.id });
      }, 250);
    }
  }, []);

  useEffect(() => {
    if (tabsRef.current) {
      const tabsObserver = new IntersectionObserver(
        (entries, observer) => {
          setIsTabsShowing(entries[0].isIntersecting);
        },
        { threshold: 1 }
      );

      tabsObserver.observe(tabsRef.current);
    }
  }, [tabsRef]);

  useEffect(() => {
    if (response?.data) {
      const filteredData =
        formattedItems?.length > 0
          ? [...response?.data?.items?.other_items].filter(
              (i) => formattedItems.findIndex((item) => item.id === i.id) === -1
            )
          : [...(response?.data?.items?.other_items ?? [])];

      const itemsToSave = [...formattedItems, ...filteredData];

      if (response?.data?.items?.featured_items?.length > 0) {
        setFeaturedItems([...response?.data?.items?.featured_items]);
      }

      setPagData({
        next_page: response?.next_page,
        prev_page: response?.prev_page,
        total_pages: response?.total_pages,
      });
      setItems(itemsToSave);
      setFetchingCategory(false);
      data.category = null;
      data.search = null;
    }
  }, [response]);

  const isViewModeEnabled = (mode) => store?.configuration.view_modes[mode];
  const modesToShow = STORE_FRONT_VIEW_MODES.filter((m) => isViewModeEnabled(m.key));

  const openItem = (item: ProductItemInterface) => {
    setSelectedItem(item);
    toggleModal("product_details");
  };

  const goNext = () => {
    if (currentPage !== items.length / PER_PAGE) return;

    handleNext();
  };

  const resetPage = () => {
    setPage(1);
    setItems([]);
  };

  if (notFound || error || !store) {
    return <StoreFrontErrors {...{ notFound, slug, error, store: store }} />;
  }

  if (disabled_slug) {
    return (
      <>
        <SEOTags tags={getStoreFrontSEOTags(slug, store)} store={store} />
        <SlugDisabledMode store={store} />
      </>
    );
  }

  if (maintenanceMode) {
    return (
      <>
        <SEOTags tags={getStoreFrontSEOTags(slug, store)} store={store} />
        <StoreMaintenanceMode store={store} />
      </>
    );
  }

  return (
    <>
      <SEOTags tags={getStoreFrontSEOTags(slug, store)} store={store} />
      <CartContext.Provider
        initialState={{
          storeId: store.id,
          cartRef: store.id,
          account: fetchStoreBankAccountReq?.response?.data,
          currencySettings: store?.currencies
            ? {
                active: store?.currencies?.storefront_default,
                rates: store?.currencies?.rates,
                options: store?.currencies?.storefront,
                defaultCurrency: store?.currencies?.products,
                allRates: store["meta"]?.["rates"]?.rates ?? {},
                rateId: store["meta"]?.["rates"]?.id ?? "",
                markups: store?.currencies?.rates,
              }
            : undefined,
        }}
      >
        {fullySetup && (
          <div>
            {store.configuration.custom_message && storeIs.open && (
              <div className="bg-white">
                <div className="w-full px-6.25 py-3 flex items-center justify-center bg-accent-yellow-500 bg-opacity-20 text-black-secondary text-1xs sm:text-sm font-medium ">
                  {data.store.configuration.custom_message}
                </div>
              </div>
            )}
            {!storeIs.open && (
              <div className="w-full px-6.25 py-3 flex items-center justify-center text-cart-badge bg-cart-badge bg-opacity-10 text-1xs sm:text-sm font-medium ">
                {getStoreIsClosedMsgToDisplay()}
              </div>
            )}
            <StoreFrontHeader
              isTabsShowing={isTabsShowing}
              itemsLoading={isLoading}
              resetPage={resetPage}
              store={store}
            />

            <main
              className={`w-full px-5 sm:px-9 md:px-12.5 mx-auto md:max-w-[1000px] lg:max-w-[1100px] py-7.5 sm:py-10 ${
                viewMode !== "swiping-cards" ? "!pb-[100px]" : "!pb-[50px]"
              }`}
            >
              <StoreFrontDetails store={data.store} />
              <div className="mt-8 sm:mt-10 lg:mt-12.5 flex items-center justify-between" ref={tabsRef}>
                <div className="overflow-hidden flex-1 pb-1 ">
                  <StoreFrontTabs categories={data.store.categories} itemsLoading={isLoading} resetPage={resetPage} />
                </div>
                {modesToShow.length > 1 && (
                  <div className="flex items-center pl-5 space-x-1.5">
                    {modesToShow.map((mode, index) => (
                      <button
                        className={`h-8 w-8 sm:h-9 sm:w-9 rounded-10 flex items-center justify-center flex-shrink-0 p-1.5  ${
                          viewMode === mode.key ? "bg-grey-bgdark text-primary-500" : "text-placeholder"
                        }`}
                        key={index}
                        onClick={() => setViewMode(mode.key)}
                      >
                        {mode.svg}
                      </button>
                    ))}
                  </div>
                )}
              </div>

              {itemsFetchError && <ItemsFetchError />}

              {isViewModeEnabled("horizontal") && (
                <div className={!itemsFetchError && viewMode === "horizontal" ? "block" : "hidden"}>
                  <ListView
                    resetPage={resetPage}
                    productsLoading={isLoading}
                    search={search || data?.search}
                    {...{
                      goNext,
                      currentPage,
                      pagData,
                      items: { main: formattedItems, featured: formattedFeaturedItems },
                      setSelectedItem: openItem,
                      isLoading: computedLoadingState,
                    }}
                  />
                </div>
              )}

              {isViewModeEnabled("card") && (
                <div className={!itemsFetchError && viewMode === "card" ? "block" : "hidden"}>
                  <SwipingCardsView
                    resetPage={resetPage}
                    productsLoading={computedLoadingState}
                    search={search || data?.search}
                    storeId={store.id}
                    {...{
                      goNext,
                      currentPage,
                      pagData,
                      items: { main: formattedItems, featured: formattedFeaturedItems },
                      category,
                      setSelectedItem: openItem,
                    }}
                  />
                </div>
              )}

              {isViewModeEnabled("grid") && (
                <div className={!itemsFetchError && viewMode === "grid" ? "block" : "hidden"}>
                  <ItemsGridView
                    resetPage={resetPage}
                    productsLoading={isLoading}
                    search={search || data?.search}
                    {...{
                      goNext,
                      currentPage,
                      pagData,
                      items: { main: formattedItems, featured: formattedFeaturedItems },
                      setSelectedItem: openItem,
                      isLoading: computedLoadingState,
                    }}
                  />
                </div>
              )}

              <StoreFrontCart storeIsOpen={storeIs.open} phoneNumber={data.store.phone} store={store} />
            </main>

            <ExplainerBanner toggleExplainer={() => toggleModal("explainer")} />
            <Portal>
              <StoreFrontExplainerModal
                show={modals.explainer}
                toggle={() => toggleModal("explainer")}
                storeName={store.name}
              />
              {selectedItem && (
                <ProductDetailsModal
                  show={modals.product_details}
                  toggle={() => toggleModal("product_details")}
                  item={selectedItem}
                />
              )}
            </Portal>
            <PublicFooter
              socials={store.socials}
              storeName={store?.name}
              hasPaidSubscription={store?.has_paid_subscription}
              refund_policy={store?.extra_info?.refund_policy}
            />
          </div>
        )}

        {!fullySetup && (
          <div className="relative h-screen overflow-hidden flex flex-col items-center justify-center">
            <div className="spinner spinner--md text-black-secondary"></div>
          </div>
        )}
      </CartContext.Provider>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async (context) => {
  const slug = context.params.slug as string;
  const query = context.query;

  return fetchStoreData({ slug, query });
};

export default StoreFront;
